import React from "react";
import { Link } from "react-router-dom";

import "./css/bootstrap.min.css";
import "./css/Style.css";
import * as bootstrap from "bootstrap";

function Header(props) {
  let home = "",
    download = "",
    contact = "",
    partners = "",
    features = "";
  if (props.active === "home") {
    home = "active-link";
  }
  if (props.active === "download") {
    download = "active-link";
  }
  if (props.active === "contact") {
    contact = "active-link";
  }
  if (props.active === "partners") {
    partners = "active-link";
  }
  if (props.active === "features") {
    features = "active-link";
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark ">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            alt="DigiNanny"
            className="imgLogo"
            src={require("./images/logo.png")}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav  mr-auto"></div>
          <div className="navbar-nav  my-2 my-md-0">
            <Link className={`nav-item nav-link ${features} `} to="/features">
              Features{" "}
            </Link>
            <Link className={`nav-item nav-link ${partners}`} to="/partners">
              Partners{" "}
            </Link>
            <Link className={`nav-item nav-link ${download}`} to="/download">
              Download
            </Link>
            {/* <Link className={`nav-item nav-link ${contact}`} to="/contact">
              Contact{" "}
            </Link> */}
            <a
              className="nav-item nav-link"
              href="https://web.diginanny.com/#/map"
              target="_blank"
            >
              {" "}
              Web App
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
