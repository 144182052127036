import React, { Component } from "react";
/*import $ from 'jQuery';*/
import "./css/bootstrap.min.css";
import "./css/Style.css";
import "./css/animate.css";
let WOW = require("wowjs").WOW;

class Partners_ extends Component {
  constructor(props) {
    super(props);
    this.img_mouseEnter = this.img_mouseEnter.bind(this);
    this.img_mouseLeave = this.img_mouseLeave.bind(this);
  }
  img_mouseEnter(event) {
    event.target.classList.add("jello");
    event.target.className = Array.from(event.target.classList).join(" ");
    this.render();
  }

  img_mouseLeave(event) {
    event.target.classList.remove("jello");
    event.target.className = Array.from(event.target.classList).join(" ");
    this.render();
  }
  render() {
    return (
      <div className="container partnersContainer">
        <div className="row partnersCardContainer">
          <div className="card">
            <img
              src={require("./images/DigiNanny for Cities.png")}
              onMouseLeave={event => this.img_mouseLeave(event)}
              onMouseEnter={event => this.img_mouseEnter(event)}
              className="partner_img wow card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5>DigiNanny for Cities</h5>
              <p className="card-text">
                You are an infrastructure decision maker about developing a
                smart city? You would definitely need our map-based data
              </p>
            </div>
          </div>
          <div className="card">
            <img
              src={require("./images/DigiNanny and Banking.png")}
              onMouseLeave={event => this.img_mouseLeave(event)}
              onMouseEnter={event => this.img_mouseEnter(event)}
              className="partner_img wow card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5>DigiNanny and Banking</h5>
              <p className="card-text">
                Auto transactions for parents who are using reward system
              </p>
            </div>
          </div>
          <div className="card">
            <img
              src={require("./images/DigiNanny for Disabilities.png")}
              onMouseLeave={event => this.img_mouseLeave(event)}
              onMouseEnter={event => this.img_mouseEnter(event)}
              className="partner_img card-img-top  wow  "
              alt="..."
            />
            <div className="card-body">
              <h5>DigiNanny for Disabilities Organisations</h5>
              <p className="card-text">
                {" "}
                Rely on the data for better collaboration and provide a barrier
                free map
              </p>
            </div>
          </div>
          <div className="card">
            <img
              src={require("./images/DigiNany for Childrens Events Program.png")}
              onMouseLeave={event => this.img_mouseLeave(event)}
              onMouseEnter={event => this.img_mouseEnter(event)}
              className=" partner_img wow card-img-top wow"
              alt="..."
            />
            <div className="card-body">
              <h5>DigiNany for Children's Events Program</h5>
              <p className="card-text">
                Organizations who are planning an event can announce it on
                DigiNanny map
              </p>
            </div>
          </div>

          <div className="card">
            <img
              src={require("./images/DigiNanny and Schools.png")}
              onMouseLeave={event => this.img_mouseLeave(event)}
              onMouseEnter={event => this.img_mouseEnter(event)}
              className=" partner_img card-img-top wow"
              alt="..."
            />
            <div className="card-body">
              <h5>DigiNanny and Schools and Kindergartens</h5>
              <p className="card-text">
                Schools can improve and provide their amenities to all kids
                around
              </p>
            </div>
          </div>
          <div className="card">
            <img
              src={require("./images/Diginanny for Broadcasters.png")}
              onMouseLeave={event => this.img_mouseLeave(event)}
              onMouseEnter={event => this.img_mouseEnter(event)}
              className="partner_img card-img-top wow"
              alt="..."
            />
            <div className="card-body">
              <h5>Diginanny for Broadcasters</h5>
              <p className="card-text">
                Report any children related breaking news and share it with
                parents community
              </p>
            </div>
          </div>
          <div className="card">
            <img
              src={require("./images/DigiNanny Advertising.png")}
              onMouseLeave={event => this.img_mouseLeave(event)}
              onMouseEnter={event => this.img_mouseEnter(event)}
              className="partner_img card-img-top wow"
              alt="..."
            />
            <div className="card-body">
              <h5>DigiNanny Advertising</h5>
              <p className="card-text">
                Advertise your business to parents around
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Partners extends Component {
  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
  }
  componentDidMount() {
    var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      callback: function(box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null // optional scroll container selector, otherwise use window
    });
    wow.init();
  }
  changeState() {
    this.render();
  }
  render() {
    return <Partners_ />;
  }
}
export default Partners;
