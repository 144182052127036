import React from "react";
import "./css/bootstrap.min.css";
import "./css/Style.css";

function About() {
  return (
    <div className="container ">
      <div className=" row spaceing30"> </div>
      <div className="contactContainer">
        <h1>About </h1>
        <h6>
          {" "}
          DigiNanny helps parents to raise a better generation easier with more
          fun.
        </h6>
        <p>
          {" "}
          We are here to make a community of parents who want to use the power
          of virtual world for the benefit of our kids.
        </p>
        <h6>
          {" "}
          We are a global community of parents, guardians, teachers, disorders
          NGOs to commit a better world for our kids who are the future.
        </h6>
        <p>
          {" "}
          Our team are experts and professionals in different domains: urban
          designer, behavioral consulting and software engineering.{" "}
        </p>
      </div>
      <div className=" row spaceing30"> </div>
      <div className=" row spaceing30"> </div>
      <div className=" row spaceing30"> </div>
      <div className=" row spaceing30"> </div>
    </div>
  );
}

export default About;
