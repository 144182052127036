import React from 'react'
import './css/bootstrap.min.css';
import './css/Style.css';
import './css/StyleImageEffect.css';


function Team() {
    return (
        <div className="container ">
            <div className=" row spaceing30"> &nbsp; </div>
            <h1>Our Team  </h1>
            <div className="row contactContainer ">
                <div className="imgContainer">
                    <div class="photobox photobox_type18">
                        <div class="photobox__previewbox">
                            <img src={require('./images/Anahita Mohammadi.jpg')} className="photobox__preview" alt="Anahita Mohammadi" />
                            <span class="photobox__label">Anahita Mohammadi</span>
                        </div>
                    </div>
                    <div class="photobox photobox_type18">
                        <div class="photobox__previewbox">
                            <img src={require('./images/Malihe GHanizade.jpg')} className="photobox__preview" alt="Malihe Ghanizadeh" />
                            <span class="photobox__label">Malihe Ghanizadeh</span>
                        </div>
                    </div>
                </div>
                <div className="imgContainer">
                    <div class="photobox photobox_type18">
                        <div class="photobox__previewbox">
                            <img src={require('./images/Vahid Sodagaran.jpg')} className="photobox__preview" alt="Vahid Sodagaran" />
                            <span class="photobox__label">Vahid Sodagaran</span>
                        </div>
                    </div>
                    <div class="photobox photobox_type18">
                        <div class="photobox__previewbox">
                            <img src={require('./images/Maryam Shokrollahi.jpg')} className="photobox__preview" alt="Maryam Shokrollahi" />
                            <span class="photobox__label">Maryam Shokrollahi</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" row spaceing30"> </div>

        </div>
    );
}

export default Team;