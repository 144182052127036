import React, { Component } from "react";
import "./css/bootstrap.min.css";
import "./css/Style.css";
import Pdf from "./documents/DigiNanny.pdf";

class Proposal_ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: false,
    };
    this.myRefPDF = React.createRef();
    let _investor = props.investor;
    if (_investor);
    else
      _investor = {
        name: "Unkown",
        code: "0",
      };
    this.investor = _investor;

    this.onFieldChange = this.onFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onFieldChange(event) {
    this.setState({
      [event.target.id]: event.target.checked,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.investor.code !== "0" && this.state.terms) {
      this.myRefPDF.current.click();
    } else {
      alert("Please contact us for having access to DigiNanny proposal!");
      window.open("/contact");
    }
  }

  render() {
    return (
      <div className="container ">
        <div className=" row spaceing30 "> &nbsp; </div>
        <div className="row    textContainer imgContainer spaceing30">
          <div className="col-9">
            <h4>Welcome Dear {this.investor.name} !</h4>
            Please send us your feedback to proposal@diginanny.com
          </div>
        </div>
        <div className="row  imgContainer ">
          <div className="col-7 col-sm-9  card NDAContainer scrollbar-night-fade card-body">
            <h5>
              <strong>
                Confidentiality and Non-Disclosure Agreement For DigiNanny
                Project
              </strong>
            </h5>
            <p>
              WHEREAS, Disclosing Party intends to disclose to the Recipient
              certain Confidential Information which may be disclosed orally,
              visually, and in written form including but not limited to
              electronic or other media with regard to the above referenced
              project / consultation and in the development of such transaction
              (“the Purpose”). For purposes of this Agreement, “Confidential
              Information” shall include all confidential information or
              confidential material this is disclosed to Recipient by Disclosing
              Party that has commercial value or other utility in the business
              of Disclosing Party, and that is marked or clearly identified as
              “confidential”, communicated to be confidential or by its very
              nature would reasonably be assumed confidential.
            </p>
            <p>
              NOW, THEREFORE, in consideration of the premises, the covenants
              and obligations hereinafter set forth, and other good and valuable
              consideration, the receipt and sufficiency of which are hereby
              acknowledged by the parties, it is therefore agreed as follows:
            </p>
            <p>
              1. The Recipient agrees to use the Confidential Information only
              for and in the development of the Purpose and agrees not to
              disclose the Confidential Information to any other person or
              entity without the prior written consent of Disclosing Party. The
              Recipient will notify the Disclosing Party immediately in writing
              upon discovery of any unauthorized disclosure or use of
              Confidential Information or any other violation of this Agreement.
              The Recipient will use all reasonable means to regain possession
              of the Confidential Information and/or prevent further
              unauthorized use, disclosure or violation.
            </p>
            <p>
              2. The Recipient agrees to return to Disclosing Party all
              Confidential Information, or such portions thereof as are
              designated by the Disclosing Party, including all tangible
              reproductions of such Confidential Information, within thirty (30)
              business days after written request. The Recipient shall not be
              required to remove copies of the Disclosing Party’s Confidential
              Information from its backup media and servers, where doing so
              would be commercially impracticable. In addition, the foregoing
              destruction and return obligation shall be subject to any
              retention obligations imposed on Receiving Party by law,
              regulation or internal legal record policy.
            </p>
            <p>
              3. The foregoing obligations of confidentiality shall not apply to
              any Confidential Information: which was already known by the
              Recipient at the time of the disclosure by the Recipient; or that
              information which is lawfully obtained by the Recipient from a
              person or entity not a party to this Agreement without any
              obligation of confidentiality; or any information that is now, or
              hereafter becomes, through no act or failure to act on the part of
              Recipient, known to the public. However, the Recipient agrees to
              protect information obtained from Disclosing Party in good faith
              and in accordance with the intent of this Agreement.
            </p>
            <p>
              4. All Confidential Information, including reproductions, shall be
              deemed to be and remain the property of Disclosing Party, and no
              ownership rights of any kind in and to the Confidential
              Information shall be transferred to the Recipient without the
              express written permission of Disclosing Party. Recipient shall
              obtain no intellectual property rights, including patent,
              copyright, or trademark ownership rights in the Confidential
              Information.
            </p>
            <p>
              5. Recipient agrees to indemnify and defend Disclosing Party and
              hold it harmless from and against any and all claims, liabilities,
              obligations, judgments, causes of actions, costs and expenses
              (including reasonable attorneys’ fees) arising out of any breach
              of this Agreement.
            </p>
            <p>
              6. The parties agree that it is impossible to measure in money the
              damages that will accrue to either party due to a party’s breach
              of this Agreement and/or failure to perform any of the obligations
              under this Agreement. Therefore, in the event that Disclosing
              Party institutes any action or proceeding to specifically enforce
              the provisions of this Agreement by injunctive or other form of
              equitable relief, the Recipient hereby waives the claim or defense
              that Disclosing Party has an adequate remedy at law or Disclosing
              Party has not been or is not being irreparably harmed, and the
              Recipient shall not assert in any such action or proceeding the
              claim or defense that such remedy at law exists.
            </p>
            <p>
              7. The provisions of this Agreement are severable. No delay or
              omission by either party hereto to exercise any right occurring
              upon any noncompliance or breach by the other party with respect
              to any of the terms of this Agreement shall impair any such right
              or power or be construed to be a waiver thereof. Nothing in this
              Agreement creates any license, franchise or agency relationship,
              partnership, or joint venture between the parties.
            </p>
            <p>
              8. This Agreement will automatically expire one (1) year after the
              Effective Date. In the event the parties enter into a definitive
              written agreement in connection with the Purpose, this Agreement
              will be superseded by the confidentiality provisions in such
              definitive written agreement from and after the execution thereof.
              Notwithstanding the expiration of this Agreement, the obligations
              of Recipient shall continue in full force and effect for a period
              of two (2) years from the date of expiration.
            </p>
            <p>
              9. This Agreement shall be governed by and enforced in accordance
              with the laws of Ontario and adjudicated therein. The persons
              signing this Agreement warrant that they have authority to bind
              their organization.
            </p>
          </div>
        </div>
        <div className=" row spaceing30 "> &nbsp; </div>

        <form onSubmit={this.handleSubmit}>
          <div className="row imgContainer">
            <div className="col-12 imgContainer spaceing30">
              <input
                onChange={this.onFieldChange}
                type="checkbox"
                required
                id="terms"
                checked={this.state.terms}
              />
              &nbsp;I agree
            </div>
            <p className=" gradient-buttons">
              <button
                type="submit"
                className="btn btn-download"
                //onClick={event => this.btn_onClick(event)}
              >
                Download Proposal
              </button>
            </p>
          </div>
        </form>
        <a
          ref={this.myRefPDF}
          href={Pdf}
          target="_blank"
          style={{ visibility: "hidden" }}
        >
          Download Pdf
        </a>
        <div className=" row spaceing30 "> &nbsp; </div>
      </div>
    );
  }
}

class Proposal extends Component {
  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);

    const investorCode = this.props.queryParams.get("investorCode");
    this.investor = investors.find(
      (investor) => investor.code === investorCode
    );
  }
  componentDidMount() {}
  changeState() {
    this.render();
  }
  render() {
    return <Proposal_ investor={this.investor} />;
  }
}
export default Proposal;

const investors = [
  {
    name: "Maple Leaf Angels",
    code: "123456",
  },
  {
    name: "Vancouver Angel Technology Network",
    code: "234567",
  },
  {
    name: "Anges Quebéc",
    code: "345678",
  },
  {
    name: "Dr Sohrabpour",
    code: "IranQuantum",
  },
];
