import React from 'react'
import './css/bootstrap.min.css';
import './css/Style.css';
import {Link} from "react-router-dom";

export function NeedHelpPanel()
{
    return(
        <div className="container">
            <div className="row imgContainer">
                <div className=" col-md-7   needHelpPanel textContainer ">
                        <a className=""  href="/contact" >
                            <h5>     Need help ?  </h5>    
                        </a>
                </div>
            </div>
            <div className="spaceing30">
                &nbsp;
            </div>
        </div>
    );
}
export function HelpCenter(){
    return(
     <div className="container ">
         <div className=" row spaceing30"> </div>
         <div className="contactContainer">
             <h1>HelpCenter</h1>
             
             
         </div>
         <div className=" row spaceing30"> </div>
     
     
     </div>
   ); 
 }
 
 export default HelpCenter;