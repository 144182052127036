import React from 'react'
import './css/bootstrap.min.css';
import './css/Style.css';


function Copyright(){
    return(
        <div className="container ">
        <div className=" row spaceing30"> </div>
        <div className="row">
        <div className="col-sm-12 col-md-2" >

 <ul className="list-unstyled">
     <li>
         <a href="/terms">Term Of Use</a>
     </li>
     <li>
         <a href="/privacy">Privacy Policy</a>
     </li>
     <li>
         <a href="/copyright">Copyright Notice</a>
     </li>
 </ul>
</div>
        <div className="col-sm-12 col-md-10" >
        <h1>Copyright Policy</h1>


<p>DigiNanny Mobile Ltd. ("<b>DigiNanny</b>")
respects the intellectual property rights of others and requests the
users of its services to do the same. If you believe that certain content,
which appears on DigiNanny real-time road maps (the "<b>Service</b>"),
infringes upon copyrights that you own or represent, you may send DigiNanny's
designated copyright agent ("<b>Copyright Agent</b>") a written
notification, stating the location of the copyrighted work claimed to
be infringed, in accordance with the provisions of the Digital Millennium
Copyright Act ("<b>DMCA</b>"). </p>

<p>Upon your notification,
DigiNanny may remove or disable access to any such content. </p>
<p>To be effective, your
notification of claimed infringement must be a written communication
provided to the Copyright Agent that includes substantially the following: </p>
<ol>
<li><p>A physical or
electronic signature of a person authorized to act on behalf of the
owner of an exclusive right that is allegedly infringed; </p></li>
<li><p>Identification
of the copyrighted work claimed to be infringed via using the Website,
or if multiple copyrighted works are covered by a single notification,
a representative list of such works;  </p></li>
<li><p>Identification
of the material that is claimed to infringe or to be the subject of
infringing activity and that access to which is to be disabled, and
information reasonably sufficient to permit DigiNanny to locate the material,
including the exact URI (Uniform resource Identifier) or coordinates
on the Service's maps in which you discovered the copyrighted work claimed
to be infringed;  </p></li>
<li><p>Information reasonably
sufficient to permit DigiNanny to contact you, such as an address, telephone
number, and, if available, an electronic mail address at which you may
be contacted;  </p></li>
<li><p>A statement that
you have a good faith belief that the use of the material in the manner
complained of is not authorized by the copyright owner, its agent, or
the law;  </p></li>
<li><p>A statement that
the information in the notification is accurate, and under penalty of
perjury, that you are authorized to act on behalf of the owner of an
exclusive right that is allegedly infringed. </p></li>
</ol>
<p>Following receipt of
your communication, DigiNanny may ask you to provide further or supplemental
information, prior to removing any content which was displayed on the
Website, as DigiNanny deems necessary to comply with the provisions of the
DMCA. DigiNanny may also provide the registered user who uploaded the allegedly
infringing content with your contact details, in order for that person
to be able to contact you directly and communicate with you regarding
your notification. </p>
<p><b>Counter notification</b></p>
<p>You may submit a counter
notification to the Copyright Agent. To be effective, your counter notification
must be a written communication provided to the Copyright Agent that
includes substantially the following:</p>
<ol>
<li><p>Your physical
or electronic signature;</p></li>
<li><p>Identification
of the material to which access has been disabled and the location at
which the material appeared before access to it was disabled. </p></li>
<li><p>A statement under
penalty of perjury that you have a good faith belief that the material
was disabled as a result of mistake or misidentification of the material
to be disabled;</p></li>
<li><p>Your name, address,
and telephone number, and a statement that you consent to the jurisdiction
of the Federal District Court for the judicial district in which your
address is located, or if your address is outside of the United States,
for any judicial district in which you may be found, and that you will
accept service of process from the person who provided notification
or an agent of such person.</p></li>
</ol>
<p>After receipt of a counter
notification, DigiNanny will promptly provide the person who submitted the
claimed copyright infringement notification with a copy of the counter
notification, and will inform that person that DigiNanny will replace the
removed material, or cease disabling access to that material 10 business
days. </p>
<p>DigiNanny will then replace
the removed the material and cease disabling access to it between 10
to 14 business days following receipt of the counter notice, unless
the Copyright Agent first receives notice from the person who notified
DigiNanny of the claimed copyright infringement that such person has filed
an action seeking a court order to restrain the registered user from
engaging in infringing activity relating to the material on DigiNanny's system
or network.</p>

<p><b>Repeat infringements</b></p>
<p>DigiNanny may deny or cancel
any individual use of the Website, or terminate your user account, if
DigiNanny determines in its sole discretion that you are a repeat infringer.
A repeat infringer is a user who has been notified of infringing activity
more than once or a user that his or her uploaded material was removed
from the Website more than once. DigiNanny may decide, at its sole discretion
that a sufficient reason exists for the immediate termination of your
account for any reason, at any time. In these cases DigiNanny may terminate
your account immediately. DigiNanny may notify you that it canceled your
account by sending a message to the e-mail address that you provided
during the registration process. Such notification will come into effect
immediately. </p>
<p><b>Copyright Agent Details</b></p>
<p>The Copyright Agent is
Morphotect Design Group, DigiNanny Mobile Ltd., Toronto, Canada
Telephone:  +1 (647) 708-4807 <br />
Email: <a href="mailto:copyright@linqmap.com" target="_blank">copyright@digiNanny.com</a>.</p>
<p>Please note that the
Copyright Agent receives DMCA notifications only. Any other matters,
such as comments, requests and other messages should be referred to:
<a href="mailto:support@digiNanny.com" target="_blank">support@digiNanny.com</a> </p>
<p><b>Misrepresentations</b></p>
<p>
Under the provisions of the DMCA, any person who knowingly materially
misrepresents: (1) that material is infringing, or (2) that material was
removed or disabled by mistake or misidentification, will be liable for any
damages, including costs and attorneys' fees, that DigiNanny may incur, if it is
injured by such misrepresentation, as the result of its reliance upon such
misrepresentation in removing or disabling access to the material claimed to be
infringing.
</p>

        </div>
        </div>
        <div className=" row spaceing30"> </div>
        
    </div>
   ); 
 }
 
 export default Copyright;