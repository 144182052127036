import React, { Component } from "react";
//import {Link} from "react-router-dom";
import "./css/bootstrap.min.css";
import "./css/animate.css";
import "./css/Style.css";
import { DownloadPanel } from "./Download";
import * as bootstrap from "bootstrap";
let WOW = require("wowjs").WOW;

function FeaturesBody() {
  return (
    <div className="container">
      <div className=" row spaceing30"> </div>

      <div className="row spaceing30 flex-row-reverse">
        <div className="col-md-6 col-sm-12 textContainer boxPadding">
          <h3> Your kid’s buddy is not that far! </h3>
          <br />
          <h6 className="textGray">
            Find nearby buddies and set playdates for your kid with other kids
            of similar interest and favorite activities
          </h6>
        </div>
        <div className="col-md-6 col-sm-12 rounded imgContainer img3_back">
          <img
            alt=""
            src={require("./images/map3-1.png")}
            className="imgLayers"
          />
          <img
            alt=""
            src={require("./images/map3-2.png")}
            className="imgLayers wow bounceInRight delay-1s"
          />
          <img
            alt=""
            src={require("./images/map3-3.png")}
            className="imgLayers wow bounceInLeft delay-1s"
          />
          <img
            alt=""
            src={require("./images/map3-4.png")}
            className="imgLayers wow bounceInUp delay-2s"
          />
          <img
            alt=""
            src={require("./images/map3-5.png")}
            className="imgLayers wow bounceInDown delay-2s"
          />
          <img
            alt=""
            src={require("./images/map3-6.png")}
            className="imgLayers wow bounceIn delay-3s"
          />
        </div>
      </div>

      <div className="row spaceing30">
        <div className="col-md-6 col-sm-12 textContainer boxPadding">
          <h3>
            Get to know your kid’s strength points through DigiNanny’s dynamic
            profiling
          </h3>
          <br />
          <h6 className="textGray">
            DigiNanny reveals available and nearby resources and opportunities
            to boost your kid’s strength area points
          </h6>
        </div>
        <div className="col-md-6 col-sm-12 rounded imgContainer img5_back">
          <img
            alt=""
            src={require("./images/map5-1.png")}
            className="imgLayers"
          />
          <img
            alt=""
            src={require("./images/map5-2.png")}
            className="imgLayers wow zoomIn delay-1s"
          />
          {/*<img alt="" src={require('./images/map5-3.png')} className="imgLayers wow bounceIn delay-2s zoomOut" /> */}
          <img
            alt=""
            src={require("./images/map5-4.png")}
            className="imgLayers wow bounceIn delay-4s fadeOut"
            data-wow-duration="2s"
            data-wow-iteration="1000"
          />
        </div>
      </div>
      <DownloadPanel />
      <div className="row spaceing30"></div>
      <div className="row spaceing30 flex-row-reverse">
        <div className="col-md-6 col-sm-12 textContainer boxPadding">
          <h3>Monitor and improve your kid’s behaviour</h3>
          <br />
          <h6 className="textGray">
            DigiNanny’s reward system is an efficient way to improve your kid’s
            behaviour and motivate them acknowledging their efforts
          </h6>
        </div>
        <div className="col-md-6 col-sm-12 rounded imgContainer img6_back">
          <img
            alt=""
            src={require("./images/map6-1.png")}
            className="imgLayers"
          />
          <img
            alt=""
            src={require("./images/map6-2.png")}
            className="imgLayers wow bounceInDown delay-1s"
          />
          <img
            alt=""
            src={require("./images/map6-3.png")}
            className="imgLayers wow zoomIn delay-1s "
          />
          <img
            alt=""
            src={require("./images/map6-4.png")}
            className="imgLayers wow bounceIn delay-2s "
          />
          <img
            alt=""
            src={require("./images/map6-5.png")}
            className="imgLayers wow bounceIn delay-2s "
          />
        </div>
      </div>
    </div>
  );
}

class Features extends Component {
  componentDidMount() {
    var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      callback: function(box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null // optional scroll container selector, otherwise use window
    });
    wow.init();
  }

  render() {
    return <FeaturesBody />;
  }
}
export default Features;
