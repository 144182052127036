import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, withRouter } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import App from "./App";
import Header from "./Header";
import Footer from "./Footer";
import Partners from "./Partners";
import Features from "./Features";
import Download from "./Download";
import Contact from "./Contact";
import About from "./About";
import Team from "./Team";
import JoinUs from "./JoinUs";
import { NeedHelpPanel, HelpCenter } from "./HelpCenter";
import FAQ from "./FAQ";
import FixMap from "./FixMap";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Copyright from "./Copyright";
import Proposal from "./Proposal";
import * as serviceWorker from "./serviceWorker";
import { StringDecoder } from "string_decoder";

const AppWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={"home"} />
      <App />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const PartnersWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={"partners"} />
      <Partners />
      <Footer />
    </div>
  );
});
const FeaturesWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={"features"} />
      <Features />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const DownLoadWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={"download"} />
      <Download />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const ContactWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={"contact"} />
      <Contact />
      <Footer />
    </div>
  );
});
const AboutWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={""} />
      <About />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const TeamWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={""} />
      <Team />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const JoinUsWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={""} />
      <JoinUs />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const HelpCenterWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={""} />
      <HelpCenter />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const FAQWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={""} />
      <FAQ />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const FixMapWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={""} />
      <FixMap />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const TermsWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={""} />
      <Terms />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const PrivacyWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={""} />
      <Privacy />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const CopyrightWarpper = withRouter(({ history }) => {
  return (
    <div>
      <Header active={""} />
      <Copyright />
      <NeedHelpPanel />
      <Footer />
    </div>
  );
});
const proposalWarpper = withRouter(({ history, location, match }) => {
  const queryParams = new URLSearchParams(location.search);
  return (
    <div>
      <Header active={""} />
      <Proposal queryParams={queryParams} />
      <Footer />
    </div>
  );
});

ReactDOM.render(
  <BrowserRouter>
    <React.Fragment>
      <Route exact path="/" component={AppWarpper} />
      <Route path="/partners" component={PartnersWarpper} />
      <Route path="/features" component={FeaturesWarpper} />
      <Route path="/download" component={DownLoadWarpper} />
      <Route path="/contact" component={ContactWarpper} />
      <Route path="/about" component={AboutWarpper} />
      <Route path="/team" component={TeamWarpper} />
      <Route path="/join" component={JoinUsWarpper} />
      <Route path="/help-center" component={HelpCenterWarpper} />
      <Route path="/faq" component={FAQWarpper} />
      <Route path="/fix-map" component={FixMapWarpper} />
      <Route path="/terms" component={TermsWarpper} />
      <Route path="/privacy" component={PrivacyWarpper} />
      <Route path="/copyright" component={CopyrightWarpper} />
      <Route path="/proposal" component={proposalWarpper} />
    </React.Fragment>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
