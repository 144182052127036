import React, { Component } from "react";
import axios from "axios";
import "./css/bootstrap.min.css";
import "./css/Style.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { apiResponse: "" };
    this.state = {
      name: "",
      email: "",
      comment: "",
    };
    this.onFieldChange = this.onFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {}

  onFieldChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state);
    //this.props.onAddAuthor(this.state);
    // https://blog.mailtrap.io/react-send-email/
  }

  render() {
    return (
      <div className="container ">
        <p className="App-intro">{this.state.apiResponse}</p>
        <div className=" row spaceing30"> </div>
        <div className="contactContainer">
          <h1>Contact Us</h1>
          <div className="row flex-row-reverse">
            <div className="col-md-4 col-xs-12 spaceing30">
              <ul className="list-group">
                <li className="list-group-item  ">
                  <b> Contact info </b>
                </li>
                <li className="list-group-item">
                  <img
                    src={require("./images/contact-email.png")}
                    alt="DigiNanny Email"
                  />
                  <span> info@diginanny.com </span>
                </li>
                <li className="list-group-item">
                  <img
                    src={require("./images/contact-phone.png")}
                    alt="DigiNanny Phone Number"
                  />
                  <span> +1 (647) 708-4807 </span>
                </li>
              </ul>
            </div>
            <div className="col-md-8 col-xs-12">
              <h5>Feel free to write us a line!</h5>
              <p>
                If you have any question, or you want to collaborate with us
                just write here or send an{" "}
                <a href="mailto: info@diginanny.com">Email</a>. We will come
                back to you very soon.
              </p>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="usr">Name *</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    id="name"
                    value={this.state.name}
                    onChange={this.onFieldChange}
                    pattern=".{3,50}"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email *</label>
                  <input
                    type="email"
                    required
                    className="form-control"
                    id="email"
                    value={this.state.email}
                    onChange={this.onFieldChange}
                    pattern=".{5,50}"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="comment">Comment *</label>
                  <textarea
                    required
                    className="form-control"
                    rows="5"
                    id="comment"
                    value={this.state.comment}
                    onChange={this.onFieldChange}
                    pattern=".{2,1000}"
                  ></textarea>
                </div>
                <div className="form-group gradient-buttons">
                  <button type="submit" className="btn btn-download">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Send&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className=" row spaceing30"> </div>
        <div className=" row spaceing30"> </div>
        <div className=" row spaceing30"> </div>
      </div>
    );
  }
}
export default Contact;
