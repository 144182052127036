import React from "react";
import { Link } from "react-router-dom";
import "./css/bootstrap.min.css";
import "./css/Style.css";

function Footer() {
  return (
    <footer className=" navbar-dark bg-dark footer">
      <div className="container">
        <div className="col-lg-12 text-center text-md-left">
          <div className="row">
            <div className="col-md-4 mx-auto">
              <h5 className=" mt-3 mb-4">DigiNanny</h5>
              <p>
                Join parents community and raise the next generation for even
                better!
              </p>
              <img
                alt=""
                src={require("./images/socialMedia.png")}
                useMap="#image-map"
              />
              <map name="image-map">
                <area
                  alt="Coming soon"
                  title=""
                  href=""
                  coords="34,28,5,2"
                  shape="rect"
                />
                <area
                  alt="Coming soon"
                  title=""
                  href=""
                  coords="74,1,103,30"
                  shape="rect"
                />
                <area
                  alt="Coming soon"
                  title=""
                  href=""
                  coords="150,0,174,30"
                  shape="rect"
                />
                <area
                  alt="Coming soon"
                  title=""
                  href=""
                  coords="215,1,249,29"
                  shape="rect"
                />
              </map>

              <ul className="list-unstyled list-inline text-center">
                <li className="list-inline-item">
                  <a className="btn-floating btn-fb mx-1">
                    <i className="fab fa-facebook-f"> </i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="btn-floating btn-tw mx-1">
                    <i className="fab fa-twitter"> </i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="btn-floating btn-gplus mx-1">
                    <i className="fab fa-google-plus-g"> </i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="btn-floating btn-li mx-1">
                    <i className="fab fa-linkedin-in"> </i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="btn-floating btn-dribbble mx-1">
                    <i className="fab fa-dribbble"> </i>
                  </a>
                </li>
              </ul>
            </div>

            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-2 mx-auto">
              <h5 className="text-uppercase mt-3 mb-4">About</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/contact">Contact Us</a>
                </li>
                <li>
                  <a href="/join">Join Us</a>
                </li>
              </ul>
            </div>

            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-2 mx-auto">
              <h5 className="text-uppercase mt-3 mb-4">Support</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="/#" className="tooltipFooter">
                    Help Center
                    <span className="tooltiptext">Coming Soon</span>
                  </a>
                </li>
                <li>
                  <a href="/#" className="tooltipFooter">
                    FAQ
                    <span className="tooltiptext">Coming Soon</span>
                  </a>
                </li>
                <li>
                  <a href="/#" className="tooltipFooter">
                    Fix a Map Issue
                    <span className="tooltiptext">Coming Soon</span>
                  </a>
                </li>
              </ul>
            </div>

            <hr className="clearfix w-100 d-md-none" />

            <div className="col-md-2 mx-auto">
              <h5 className="text-uppercase mt-3 mb-4">Legal</h5>

              <ul className="list-unstyled">
                <li>
                  <a href="/terms">Term of Use</a>
                </li>
                <li>
                  <a href="/privacy">Privacy Policy</a>
                </li>
                <li>
                  <a href="/copyright">Copyright Notice</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-12 text-md-left">
          © 2019-2020 All rights reserved for DigiNanny.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
