import React from 'react'
import './css/bootstrap.min.css';
import './css/Style.css';


function Privacy(){
    return(
        <div className="container ">
        <div className=" row spaceing30"> </div>
        <div className="row">
        <div className="col-sm-12 col-md-2" >

 <ul className="list-unstyled">
     <li>
         <a href="/terms">Term Of Use</a>
     </li>
     <li>
         <a href="/privacy">Privacy Policy</a>
     </li>
     <li>
         <a href="/copyright">Copyright Notice</a>
     </li>
 </ul>
</div>
        <div className="col-sm-12 col-md-10" >
        <h1 id="digiNanny---privacy-policy">Privacy Policy</h1>

<p>DigiNanny Mobile Limited respects your privacy. DigiNanny Mobile Limited and its affiliates (“DigiNanny” or the
“Company”) offer mapping, GPS navigation, community based services and Carpool services to users of
<a href="/">www.diginanny.net</a> (the “Website”) and/or users of Waze’s mobile applications (the “Application”)
(collectively referred to as the “Services”). When you use our services, you’re trusting us with
your information. We understand this is a big responsibility and work hard to protect your
information and put you in control.</p>

<p>This Privacy Policy is meant to help you understand what information we collect, why we collect it,
and how you can update, manage, and delete your information. This Privacy Policy covers:</p>

<ul>
  <li><a href="#user-accounts">User Accounts &amp; Accessing the Services</a></li>
  <li><a href="#information-that-is-being-collected">Information that is being collected</a></li>
  <li><a href="#why-digiNanny-collects-data">Why DigiNanny collects data</a></li>
  <li><a href="#sharing-your-information">Sharing your information</a></li>
  <li><a href="#visibility">Visibility</a></li>
  <li><a href="#integration-with-social-networks">Integration with social networks</a></li>
  <li><a href="#integration-with-other-third-party-services">Integration with other third party services</a></li>
  <li><a href="#controlling-your-personal-information">Controlling your personal information</a></li>
  <li><a href="#how-digiNanny-retains-data-we-collect">How DigiNanny retains data we collect</a></li>
  <li><a href="#how-does-digiNanny-use-aggregated-information">How does DigiNanny use aggregated information</a></li>
  <li><a href="#cookies">Cookies</a></li>
  <li><a href="#advertising-campaigns">Advertising campaigns</a></li>
  <li><a href="#information-security">Information security</a></li>
  <li><a href="#compliance-&amp;-cooperation-with-regulators">Compliance &amp; cooperation with regulators</a></li>
  <li><a href="#changes-to-this-privacy-policy">Changes to this Privacy Policy</a></li>
</ul>

<p>This Privacy Policy is incorporated as part of the <a href="/tos">DigiNanny Terms of Use</a> 
 (the “Terms of Use” or the “Terms”). <strong>Your use of
the Services is subject to the Terms of Use, <a href="/copyright">Copyright Policy</a> and this Privacy
Policy and indicates your consent to them.</strong></p>

<p>To use our Services you must be 16 years of age or older. If you are under 16, you may not download
or use the Services.</p>

<p>In this Privacy Policy, the term “personal information” means information which you provide to us
which personally identifies you, such as your name, email address or billing information, and, in
certain circumstances, your location and route information or other data which can be reasonably
linked to such information by DigiNanny.</p>

<p>You can adjust your <strong>Privacy Settings</strong> in the DigiNanny app and choose which personal information we
may collect about you and how your information is used.</p>

<h1 id="contact-digiNanny---privacy-concerns-reports">Contact DigiNanny - Privacy concerns reports</h1>

<p>If you have any questions about this Privacy Policy, or if you believe that your privacy has been
compromised in the course of using the Services, please contact DigiNanny at: <a href="mailto:privacy@digiNanny.com">privacy@digiNanny.com</a>. You may
send DigiNanny other requests, responses, questions and complaints by email at: <a href="mailto:support@digiNanny.com">support@digiNanny.com</a>.</p>

<h1 id="user-accounts">User Accounts &amp; Accessing the Services</h1>

<p>Please note that when you install the Application on your mobile device, DigiNanny will set up an account
associated with that mobile device (the “Account” or “Waze Account”). After installation, DigiNanny will
collect and use your information, including meta-data (as described below), in accordance with this
Privacy Policy. This use may include linking your information with your Account. This is the case
irrespective of whether or not you choose to create a DigiNanny username and password in the DigiNanny
Application (as explained below).</p>

<p>DigiNanny may allow you to use the DigiNanny Application <strong>whether or not you choose to set up a username for
yourself</strong>. If you choose to use the DigiNanny Services without setting up a username you may do so by
skipping the username setup stage of the application installation process. If you do so:</p>

<ul>
  <li>DigiNanny will still link all of your information with your Account and a unique identifier generated
by DigiNanny in accordance with this Privacy Policy. This helps us do things like find you the best
route.</li>
  <li>With the exception described in the bullet below, and as explained in the section
<a href="#visibility">“Visibility”</a> below, your Account and the information associated with your Account
(including your unique identifier) will be visible only to DigiNanny; and</li>
  <li>When using the Application, your approximate location and other information you may choose to
contribute will be visible to other users associated with the general username “Wazer”.</li>
  <li>Note that in order to access certain features of the Services (for example map editing, ranking,
moods) you will need to set up a unique username to identify yourself.</li>
</ul>

<p>If you do set up a DigiNanny username and password in the Application:</p>

<ul>
  <li>DigiNanny will link all of your information with your Account and that username. This includes
information received from all mobile devices running the Application on which you have chosen to
sign in using your username and password;</li>
  <li>With the exception described in the bullet below and as explained in the section
<a href="#visibility">“Visibility”</a> below, your Account and information associated with your Account will
be visible only to DigiNanny; and</li>
  <li>When using the Application, your approximate location and other information you may choose to
contribute will be visible to other users associated with that username.</li>
  <li>DigiNanny may also establish and require from time to time additional or different means of
identification and authentication for logging in and accessing the Services or for accessing
certain features or designated sections of the Services.</li>
  <li>Your login details are your responsibility. You are fully accountable for any use or misuse of
your Account and personal details as a result of conveying your login details to someone else. You
must maintain your login details in absolute confidentiality and avoid disclosing them to others.
Make sure that you change your password frequently and at least once every six months.</li>
</ul>

<h1 id="information-that-is-being-collected">Information that is being collected</h1>

<p><strong>We want you to understand the types of information we collect as you use our services</strong></p>

<p>DigiNanny collects information to provide better services to all its users - from figuring out stuff like
what’s the best route, to more complex things like monitoring traffic so you know when to leave, and
showing you relevant ads while using DigiNanny. The information DigiNanny collects, and how that information
is used, depends on how you choose to use our services and how you manage your privacy controls.</p>

<h3 id="information-you-create-or-provide-to-us">Information you create or provide to us:</h3>

<ul>
  <li>
    <p><strong>Account Information:</strong> information required when setting up a username you have chosen, such as
your name and gender. Bear in mind that false, incorrect, or out-dated information may impair
Waze’s ability to provide you with the Services and to contact you when necessary. DigiNanny will
explicitly indicate the fields for mandatory completion. If you do not enter the requisite data in
these fields, you may not be able to set up a username (but you may still use our Services as
explained in the above section “User Accounts &amp; Accessing the Services”); and/or</p>
  </li>
  <li>
    <p><strong>Information that you choose to share with DigiNanny:</strong> information that you choose to share with
DigiNanny, including a username you have chosen, reports associated with your username, phone number,
“home” or “work”, other “favorites”, destinations you may travel to, search queries, calendar
information (if you choose to activate and use certain Service features like time to leave
reminders) and files you have uploaded to the service, including voice and audio files, if
applicable. You can also choose to add payment information to your Account, if applicable,
specifically for paid services (such as DigiNanny Carpool). Even if you aren’t signed in to a DigiNanny
Account, you might choose to provide us with information - like an email address to receive
updates about our services.</p>
  </li>
  <li>
    <p><strong>Communication which you choose to conduct through the DigiNanny Services:</strong> if you choose to use DigiNanny
to communicate with other DigiNanny users or third parties through the DigiNanny Services, such as chat
messages or sending pinned locations on the DigiNanny platform. We may also collect information when
you exchange communication with DigiNanny, for example, if you submit a request, contact Waze’s support
team, or report a violation to the abuse team.</p>
  </li>
  <li>
    <p><strong>Information from your social networks which you choose to connect to your DigiNanny Account:</strong> you
may choose to connect your DigiNanny Account to your social network account(s). If you choose to do so,
you may choose to share with DigiNanny and/or other users information from your social network
accounts, such as your Facebook profile photo or other personal information shared by you. For
more information see <a href="#integration-with-social-networks">Integration with social networks</a>, below.</p>
  </li>
  <li>
    <p><strong>“Find friends feature”:</strong> if you use the “find friends” feature, DigiNanny will collect: _
Periodically, DigiNanny will collect all of the phone numbers which are stored on your device’s phone
contacts book. With regards to the “find friends” feature we only collect numbers in a form which
is anonymous to DigiNanny; This information will be used to help us find and create a list of other
DigiNanny users who you may know and with whom you may wish to connect. Note that we will not collect
names, addresses or other information from your device’s phone contacts book, however some of this
information may be saved locally on your device and may be used within searches for your friend’s
name or address within the Services. and/or _ Your phone number. Your phone number will be used to
verify your account and as part of the “find friends” feature , if applicable, to help find and
create a list of other DigiNanny users who you may know and with whom you may wish to connect.</p>
  </li>
</ul>

<p>You may also provide additional information that will be used to personalize the Services and
enhance your experience with the Services. For example, if you provide us with your “home” and
“work” information, we may provide you with what we think is the preferred route between those
locations.</p>

<h3 id="information-we-collect-as-you-use-our-services">Information we collect as you use our services</h3>

<p>We collect information about your activity in the Services, which we use to provide you with the
Services of DigiNanny, including features which are meant to personalize your experience. This includes
things like finding you the preferred route and propose favorites or destinations commonly traveled
by you to streamline your search and travel. The activity information we collect may include:</p>

<ul>
  <li><strong>Detailed location, travel and route information</strong>. Location and route information is collected
for example in the form of GPS signals (combined with a time-stamp) and other information sent by
your mobile device on which the Application is installed and activated. This location and route
information is saved to a route history of all of the journeys you have made while using the
Application. The types of location data we collect depends in part on your device and account
settings. For example, depending on your selected device settings and/or your account settings we
may collect information about your location and travel (or lack thereof) also while you are not
using the app, in order to be able to tell you when to leave for planned drives, notify you about
bad traffic in your area, provide you parking assistance, analyze how effective our ads are and
more;</li>
  <li>Terms or venues you searched;</li>
  <li>Your views and interactions with content and ads;</li>
  <li>Your voice and audio information when you use voice and audio features;</li>
  <li>Communication or other content you chose to share with any third party, including DigiNanny users
through the Services;</li>
  <li>Your activity on third-party sites and apps that you chose to link to your DigiNanny account or that
you interact with through the Service.</li>
</ul>

<h3 id="meta-data-information-about-your-device-browser-and-app-use">Meta-data (information about your device, browser and app use):</h3>

<p>Once you install the Application, DigiNanny collects information about the use of the Services and
information from the device you have DigiNanny installed on. For example:</p>

<ul>
  <li>
    <p>DigiNanny may collect and record how often you use the app and for how long, your device type, unique
identifiers, operating system type &amp; version, battery usage, the third party web pages or
applications you visit or use or interact with via the Application, information that you viewed on
our Services, advertisements that are shown to you or that you view or click on, the fact that you
used the Services to communicate with other users and the fact that you used third parties
application/services via DigiNanny to communicate with third parties, and the duration of such
communications, the Internet Protocol (IP) address and the name of the domain that serves you to
access the Services, and the geographic location of the device that you are using to log in and
use DigiNanny on.</p>
  </li>
  <li>
    <p>DigiNanny may also collect information about apps installed on your device that DigiNanny can link with -
such as third party music or media audio service apps (e.g. Spotify).</p>
  </li>
  <li>
    <p>We also collect information about the interaction of your devices with our Services, including,
crash reports, system activity, and the date, time, and referrer URL of your request.</p>
  </li>
</ul>

<h3 id="information-we-collect-about-you-from-our-partners">Information we collect about you from our partners</h3>

<p>We work with ad partners, including entities that belong to the Google group of companies, in order
to show you more relevant and useful ads based on your DigiNanny activity and information associated with
your device’s identifier (i.e, Advertising identifiers or similar technologies) shared with us by
our ad partners. Your device’s identifier can be managed in your device’s settings (for more
information: see <a href="https://support.apple.com/en-us/HT202074">here</a> and
<a href="https://policies.google.com/technologies/ads">here</a>).</p>

<p>You can decide whether we’ll show you personalized ads or not through your in-app <strong>Privacy
Settings–&gt;Ads personalization</strong>. Note: turning Ads personalization off does not turn off ads - only
their personalization. For more information see section titled
<a href="#advertising-campaigns">‘Advertising campaigns’</a> under this Privacy Policy.</p>

<p>We may also receive aggregated, non-personally identifiable information and analysis from third
parties measurement services which help us understand how our users interact with our ads. We may
also receive information from third party services about how Waze’s own marketing efforts performed
on third party sites or platforms.</p>

<p>We use various technologies to collect and store information, including, cookies, Advertising ID
available on Android mobile devices or similar technologies, local storage, browser web storage,
application data caches, databases, and server logs.</p>

<h1 id="why-digiNanny-collects-data">Why DigiNanny collects data</h1>

<p><strong>DigiNanny may use information collected from or provided by you for the following purposes:</strong></p>

<h3 id="provide-our-services">Provide our services</h3>

<p>We use your information to deliver our services. For example:</p>

<ul>
  <li>Use your location and route information and/or search query history to provide navigation
services, best route choices, traffic alerts and to provide information about sites, shops and
other places and attractions in your close vicinity; and</li>
  <li>(If you have opted in to the “find friends” feature), use your phone number and the phone numbers
which are stored in your device’s phone book to find and create a list of other DigiNanny users who you
may know and with whom you may wish to connect.</li>
  <li>To provide advertising services, including personalized ads, in accordance with the section titled
<a href="#advertising-campaigns">‘Advertising campaigns’</a> under this Privacy Policy.</li>
  <li>To share your submissions with other users of the Services and to facilitate communication between
you, DigiNanny and other users.</li>
</ul>

<h3 id="maintain--improve-our-services">Maintain &amp; improve our services</h3>

<ul>
  <li>We also use your information to ensure our services are working as intended, such as tracking
outages or troubleshooting issues you report to us. And we use your information to improve the
service or to develop new features or services. For example, DigiNanny may use your route information
to improve the accuracy of its mapping and navigation data.</li>
</ul>

<h3 id="provide-personalized-services-including-content-and-ads">Provide personalized services, including content and ads</h3>

<ul>
  <li>We use the information we collect to operate the Services and to customize your experience with
the Services. For example, DigiNanny may use your “home” and “work” and other “favorites” information
(if you choose to provide such information) or route history to offer routes based on how you’ve
chosen to travel between favorite destinations in the past. Based on your past activity on the
Services we may also suggest to you common destinations where you use the app to navigate to.</li>
  <li>We may also use the information to provide you with support and handle requests and complaints.</li>
  <li>Depending on your settings, we may also show you personalized ads based on your interests and past
activity. For more information see section titled
<a href="#advertising-campaigns">‘Advertising campaigns’</a> under this Privacy Policy.</li>
</ul>

<h3 id="measure-performance">Measure performance</h3>

<ul>
  <li>We use data for analytics and measurement to understand how our services are used. For example, we
analyze data about your usage of the Services to do things like optimize product design. We also
use the data we collected about the ads you view or interact with (e.g. click on) to help
advertisers understand the performance of their ad campaigns and to provide them with aggregated
reports. We may also use data to measure the usability and effectiveness of Waze’s own marketing
efforts on third party sites or platforms.</li>
</ul>

<h3 id="communicate-with-you">Communicate with you</h3>

<ul>
  <li>We also use your information to send you updates, notices, announcements, and additional
information related to the Services;</li>
  <li>We may also use your information to conduct surveys and questionnaires; And if you contact DigiNanny we
may keep a record of your request in order to help solve any issues you might be facing.</li>
  <li>Subject to your prior indication of consent (to the extent required under applicable law), we may
also use the email address that you provided to send you promotional and/or marketing materials.
At any time you may choose not to receive such materials by clicking on the “Unsubscribe” link in
the email messages that we may send to you. In any case, DigiNanny will not share your contact details
with any advertiser, unless explicitly permitted or required by law.</li>
</ul>

<h3 id="to-protect-digiNanny-our-users-the-public-and-for-legal-reasons">To protect DigiNanny, our users, the public and for Legal reasons</h3>

<p>We may also use and process your information and content, using different technologies to the
following purposes:</p>

<ul>
  <li>To enforce the Terms of Use or Privacy Policy;</li>
  <li>To contact you when DigiNanny believes it to be necessary;</li>
  <li>To comply with any applicable law and assist law enforcement agencies under any applicable law,
when DigiNanny has a good faith belief that Waze’s cooperation with the law enforcement agencies is
legally mandated or meets the applicable legal standards and procedures;</li>
  <li>To detect abuse and illegal activity; to detect and prevent fraud, misappropriation,
infringements, identity theft and other illegal activities and misuse of the Services;</li>
  <li>To handle breakdowns and malfunctions;</li>
  <li>To take any action in any case of dispute, or legal proceeding of any kind between you and the
Services, or between you and other users or third parties with respect to, or in relation with the
Services;</li>
  <li>For purposes provided under this Privacy Policy and Terms of Use;</li>
  <li>To help improve the safety and reliability of our Services. This includes detecting, preventing,
and responding to security risks, and technical issues that could harm digiNanny, our users, or the
public;</li>
  <li>To create aggregated and/or anonymous data (where such data does not enable the identification of
a specific user). See “How does DigiNanny use aggregated information”, below;</li>
</ul>

<p>We’ll ask for your consent before using your information for a purpose that isn’t covered in this
Privacy Policy.</p>

<h1 id="sharing-your-information">Sharing your information</h1>

<h3 id="personal-information-that-you-share">Personal information that you share</h3>

<p>The Services are based on a community of users publicly sharing information with DigiNanny and with other
members of that community.</p>

<p>In particular, users may choose to share personal information such as name, age, gender, picture,
their location information, reports and other files. This information may be shared either directly
to the Services and to all users, such as in the case of traffic reports or your Carpool profile, or
just to your friends on DigiNanny. This information may also be shared via third party services (such as
social networks) which interact with the Services upon such users’ choice.</p>

<p><strong>You can decide whether or not to upload content to the Services. However any content that you
choose to share and submit for posting is neither private nor confidential and you should expect
that it may be made public and known to others.</strong> Information you choose to upload will be posted
along with your user name and other information including personal information you attached to your
publicly available user profile . You should note that certain road incidents you report may include
your driving path within the vicinity of those incidents. Therefore, you must exercise caution and
common sense when submitting information. At the very least you should demonstrate the same degree
of caution as when publishing personal information by means other than the Internet and cellular
services.</p>

<p>In any event (but except as detailed below with respect to information shared from your social
network accounts according to your privacy settings for such social network accounts), your email
address will not be visible to others through any reports or user posts that you share or upload.</p>

<p>if you choose to either record your own voice onto the Service, or to use the voice command
features, DigiNanny will collect the voice information related to such use, including voice files. If,
after recording your own voice, you chose to share your voice recordings, other DigiNanny users and other
people will be able to download and use your voice recordings. Once downloaded, DigiNanny may not have
any control over the voice files, so you should only share content that you are comfortable being
made public.</p>

<p>We, at DigiNanny, take great measures to provide you with the best experience you can have while using
the Services. However, DigiNanny has no control over the conduct of any user and disclaims all liability
in this regard. Users are advised to carefully and thoroughly consider whether or not to make public
or available any information and carefully examine all necessary details related to any
communication with other users prior to any engagement or communication being made.</p>

<p>Participating in any user’s activities as a result, directly or indirectly, from using DigiNanny, is
entirely at your own risk. DigiNanny is not a party to any agreement entered into between the users in
any circumstances. The user has the sole and ultimate responsibility regarding compliance with all
laws, regulations or any other duty. We do not accept any liability for any loss, damage, cost or
expense that you may suffer or incur as a result of or in connection with your participation in any
activity or event initiated, held or conducted by a user or a third party nor in connection to any
agreement between the users or third parties, including any activity or event related in any way,
directly or indirectly, the Services or the use thereof.</p>

<h3 id="when-digiNanny-shares-your-information">When DigiNanny shares your information</h3>

<p>DigiNanny does not sell, rent or lease your personal information to third parties.</p>

<p>DigiNanny will not share your personal information with others, without your permission, except for the
following purposes and to the extent necessary in Waze’s good-faith discretion:</p>

<ul>
  <li>
    <p>As necessary for the facilitation and operation of the Services. For example, making public road
incidents you report and which are associated with your username and your location;</p>
  </li>
  <li>
    <p>If DigiNanny reasonably believes that you have breached the Terms of Use or is investigating potential
breach of the Terms of Use, or believes that you abused your rights to use the Services, or
performed any act or omission that DigiNanny reasonably believes to be violating any applicable law,
rules, or regulations. DigiNanny may share your information in these cases, with law enforcement
agencies and other competent authorities and with any third party as may be required to handle any
result of your wrongdoing;</p>
  </li>
  <li>
    <p>If DigiNanny is required, or reasonably believes that it is required by law to share or disclose your
information;</p>
  </li>
  <li>
    <p>In any case of dispute, or legal proceeding of any kind between you and DigiNanny, or between you and
other users with respect to, or in relation with the Services;</p>
  </li>
  <li>
    <p>In any case where DigiNanny reasonably believes that sharing information is necessary to protect
against harm to the rights, property or safety of DigiNanny, our users, or the public as required or
permitted by law, including to prevent imminent physical damage or damage to property;</p>
  </li>
  <li>
    <p>To detect, prevent, or otherwise address fraud, security, or technical issues;</p>
  </li>
  <li>
    <p>If DigiNanny organizes the operation of the Services within a different framework, or through another
legal structure or entity, or if DigiNanny is acquired by, or merged into or with another entity, or if
DigiNanny enters bankruptcy, provided however, that those entities agree to be bound by the provisions
of this Privacy Policy, with respective changes taken into consideration;</p>
  </li>
  <li>
    <p>To collect, store, hold and manage your personal information through cloud based or hosting
services or a third party or a party affiliated or connected to DigiNanny, as reasonable for business
purposes, which may be located in the European Union and the U.S.A., potentially countries outside
of your jurisdiction;</p>
  </li>
  <li>
    <p>DigiNanny may also share personal information with companies or organizations connected or affiliated
with DigiNanny, such as subsidiaries, sister-companies and parent companies (i.e. entities that belong
to the Google group of companies);</p>
  </li>
  <li>
    <p>We work with other Google’s services (such as Google Doubleclick Bid Manager) for advertising and
ad measurement purposes. In order to help advertisers decide what ads to show on DigiNanny, Google, and
websites &amp; apps that partner with Google, we may share information with these services, such as a
unique identifier for advertising (i.e, Advertising ID available on Android mobile devices or
similar technologies). You can learn more about how Google uses information from sites or apps
that use its services and how you can control that in
<a href="https://policies.google.com/technologies/partner-sites">this</a> page. For more information see
section titled <a href="#advertising-campaigns">‘Advertising campaigns’</a> under this Privacy Policy.</p>
  </li>
  <li>
    <p>We also work with selected measurement services which help us understand how our users interact
with our ads (such as DoubleClick Campaign Manager) and in order to measure Waze’s own marketing
efforts (such as AppsFlyer services), by using and sharing the device’s identifier (i.e,
Advertising ID or similar technologies).</p>
  </li>
  <li>
    <p>Personal information may also be shared with Waze’s trusted partners and service providers to
process it for us, based on our instructions and in compliance with this policy and any other
appropriate confidentiality and security measures. For example, we use service providers to help
us with customer services.</p>
  </li>
</ul>

<p>We may share information which does not personally identify you with third party transportation
services. For example, to allow you to find a carpool rider on third party transportation services,
such as Moovit, we may share a temporary unique identifier along with pick-up and drop-off points
that are generated based on proximity to your intended travel itinerary, with those third party
transportation services.</p>

<h1 id="visibility">Visibility</h1>

<p>Please note that your username and some other information will be visible to others. Note that your
username will be included along with any information you submit for posting (such as traffic reports
or chat messages). It will therefore be visible to others. Also - your friends on DigiNanny will see your
name &amp; profile photo and any other information you chose to share with them.</p>

<p>If you prefer to remain invisible on the DigiNanny map, you can set the DigiNanny Application not to show your
location on the map. To do so you should go to the Privacy Settings and adjust accordingly by
switching on to Invisible Mode. If on, <strong>some features may not be available</strong>. If you make this
choice, while you will be ‘invisible’ on the map to other users, your location and route information
will continue to be collected by DigiNanny for the purposes listed in this Privacy Policy and your
Carpool profile (if you choose to set one) may be visible to other Waze’s users.</p>

<h1 id="integration-with-social-networks">Integration with social networks</h1>

<p>You may choose to share information between your DigiNanny account and your social network account (such
as Facebook). Through this option, you may choose to have personal information and other content
about you available from social networks, transmitted and shared through the Services. Similarly,
you may choose to have personal information and other content about you available from DigiNanny,
including your location and route information, transmitted to and shared through your social network
account.</p>

<p>By setting your DigiNanny Account to integrate with social networks, including by signing onto DigiNanny by
using your social network account (such as your Facebook account and your Google account), you agree
to share information (including personal information) between DigiNanny and such social networks, for the
purposes provided under this Privacy Policy.</p>

<p>The social network’s use of information made available by DigiNanny is governed by that social network’s
privacy policy and controlled by the social network, not by this Privacy Policy or DigiNanny.</p>

<p>When you connect to the social network account through the Services for the first time or otherwise
make the Services interact with your social network account, you will be asked by the social network
to permit the social network to share your personal information stored on such social network with
DigiNanny. We collect your information from your social network account only in accordance with the
permissions granted by you (such as your name, email address, list of friends, profile picture and
other information available via the applicable social network).</p>

<p>You understand and agree that the Services may access, make available and store such information so
that it is available on and through your DigiNanny Account, until your DigiNanny Account is deleted.</p>

<p>Please note that certain information which you have defined as private on your third party social
network accounts may become public to other DigiNanny Users through the Services once you have allowed
the social network to share such information with DigiNanny (for example through your Carpool Profile).</p>

<h1 id="integration-with-other-third-party-services">Integration with other third party services</h1>

<p>DigiNanny may offer you the option to link DigiNanny to third party services that may be useful to you (like,
Spotify). Where DigiNanny detects an integration may be available (either because a third party service
attempts to link to DigiNanny, or DigiNanny detects an installed service on the device which has an
integration option, or you request a particular integration), DigiNanny will ask for confirmation to
connect the accounts. If you choose to connect the accounts, DigiNanny will ask for your permission to
share data with the third party service. You should only agree to connect accounts if you are
comfortable with the third party service receiving the data set out at the time of connecting the
accounts and DigiNanny is not responsible for the third party service’s use of data. You can disconnect
DigiNanny and the third party service at any time and DigiNanny will cease sharing data with that service from
the point of disconnection onwards.</p>

<h1 id="controlling-your-personal-information">Controlling your personal information</h1>

<p>You can always review and update certain information by visiting your Account &amp; Login settings (such
as your phone, email and more) or the in-app left menu (to update your home, work and favorites).</p>

<p>You can log on to the DigiNanny website to view your route history by going to “Edit the Map”/ “Drives”
function on the DigiNanny website. Note that in order to access certain information you will need to set
up a username. Your location and route history is associated with your Account and (if you have
chosen to set one up) your username or (if you have chosen not to set up a username) the unique
identifier generated by DigiNanny.</p>

<p>If you find that the information associated with your Account is not accurate, complete or updated,
then you should make all necessary changes to correct it. Please keep in mind that false, incorrect,
or outdated information may prevent you from setting up a username and impair the ability to provide
you with Services.</p>

<h3 id="privacy-controls">Privacy controls</h3>

<p>Your in-app Privacy Settings provide you with quick access to settings and tools that let you
safeguard your data and decide how your information can make DigiNanny services work better for you. For
example, you can control your visibility on the map and manage your preferences about the ads shown
to you on DigiNanny.</p>

<p>There are other ways to control the information DigiNanny collects whether or not you’re signed in to a
DigiNanny Account, including device-level settings: your device may have controls that determine what
information we collect. For example, you can modify location settings on your device.</p>

<h3 id="deleting-your-information">Deleting your information</h3>

<p>If you would like us to delete your Account, please use the “Delete Account” function available
under the in-app Privacy Setting and on the <a href="/dashboard">DigiNanny website</a>. On receiving such a request,
DigiNanny will use reasonable efforts to delete such information, however please note that information
may not be deleted immediately from our back-up systems. We aim to initiate the deletion process
immediately after your request. Once we receive your request, your data will no longer be used to
personalize your DigiNanny experience. We then begin a process designed to safely and completely delete
the data from our storage systems. This process generally takes around 2 months from the time of
deletion. As with any deletion process, things like routine maintenance, unexpected outages, bugs,
or failures in our protocols may cause delays in the processes and time frames defined in this
policy. We maintain systems designed to detect and remediate such issues.</p>

<h1 id="how-digiNanny-retains-data-we-collect">How DigiNanny retains data we collect</h1>

<p>Some data you can delete whenever you like, some data is deleted automatically, and some data we
retain for longer periods of time when necessary. This section describes why we hold onto different
types of data for different periods of time.</p>

<ul>
  <li><strong>Information retained until you remove it</strong> - such as your “Home, “work”, favorites, email
address and other account related information. We’ll keep this data in your DigiNanny Account until you
choose to remove it and/or update it.</li>
  <li><strong>Data that expires after a specific period of time</strong> - in some cases, you don’t need to actively
delete your information as we set retention timeframes based on the reason for the data’s
collection. For example, if you choose to connect your calendar to your DigiNanny Account, we extract
your calendar events for several days in advance and delete them after their occurrence. We may
also take steps to anonymize certain data within set timeperiods.</li>
  <li><strong>Information retained until your DigiNanny Account is deleted</strong> - we may keep some data for the life
of your DigiNanny Account if it’s useful for helping us understand how users interact with our features
and how we can improve our services. For example, if you made an incident report, we will keep
information about how many reports you have submitted, even after the event is removed. When you
delete your DigiNanny Account, the information about how many reports you have submitted is also
removed.</li>
  <li><strong>Information retained for extended time periods for limited purposes</strong> - sometimes business and
legal requirements oblige us to retain certain information, for specific purposes, for an extended
period of time. For example, when DigiNanny processes a payment for you, we’ll retain this data for
longer periods of time as required for tax or accounting purposes. Reasons we might retain some
data for extended periods of time include: _ Security, fraud &amp; abuse prevention _ Financial
record-keeping _ Complying with legal or regulatory requirements _ Ensuring the continuity of our
services * Direct communications with DigiNanny</li>
</ul>

<h1 id="how-does-digiNanny-use-aggregated-information">How does DigiNanny use aggregated information?</h1>

<p>DigiNanny may use anonymous, statistical or aggregated information (including anonymous location
information), in a form that does not enable the identification of a specific user, to properly
operate the Services, to improve the quality of the Services, to enhance your experience, to create
new services and features, including customized services, to change or cancel existing content or
service, and for further internal, commercial and statistical purposes (including advertising).</p>

<p>DigiNanny may also use anonymous, statistical or aggregated information collected on the Services, in a
form that does not enable the identification of a specific user, by posting, disseminating,
transmitting or otherwise communicating or making available such information to users of the
Services, to the Services’ providers, partners and any other third party. For example, GPS
information that we receive from your mobile device may be provided to map editors in an aggregated
and/or anonymous form to help improve the map and solve map problems, traffic data may be provided
to cities, and aggregated ads analysis may be provided to advertisers.</p>

<h1 id="cookies">Cookies</h1>

<p>DigiNanny may use cookies and similar technologies on the Services. Cookies are packets of information
sent by the Website’s servers to your web browser and then sent back by the browser each time it
accesses the Website’s servers.</p>

<p>DigiNanny may use cookies for various purposes, such as to save you the need to re-enter your username
and password each time you log in, to facilitate the use of the Website, to collect statistical
information, to verify information, and to customize the Website to your personal preferences and
for information security purposes.</p>

<p>Some of the cookies may expire when the session ends and you exit your browser. Other cookies are
saved on your computer’s hard drive. If you wish to block the Website’s cookies, then please use the
help button in your browser and follow the necessary instructions. However, bear in mind that
disabling cookies may complicate or even prevent your use of the Website, or certain features
thereof.</p>

<p>For more information visit our <a href="/cookies">cookie policy</a>.</p>

<h1 id="advertising-campaigns">Advertising campaigns</h1>

<p>DigiNanny allows advertisers to run ad campaigns on the Services.</p>

<p>The information and advertisements that you see when accessing the Services may be targeted on the
basis of your current DigiNanny activity, such as your current location, your destination or contextual
information based on your current drive session. For example: if you search for “gas station” you
may see an ad for a local gas station.</p>

<p>Depending on your settings, you may also see <strong>ads personalized</strong> to you based on:</p>

<ul>
  <li>Information we received from you (for example, your saved “Home” &amp; “Work” addresses)</li>
  <li>Past activity and search query history (for example, drives that appear in your drive list)</li>
  <li>Information from our ad partners, including entities that belong to the Google group of companies
and other trusted ad partners.</li>
</ul>

<p>You can decide whether we’ll show you personalized ads or not through your in-app <strong>Privacy
Settings–&gt;Ads personalization</strong>. Note: turning Ads personalization off does not turn off ads - only
their personalization.</p>

<p>Please note:</p>

<ul>
  <li>We don’t knowingly collect nor do we show you personalized ads based on sensitive categories, such
as race, religion, sexual orientation, or health.</li>
  <li>We don’t share information that personally identifies you with advertisers, such as your name or
email, unless you ask us to. For example, if you see an ad for a nearby flower shop and select the
“tap to call” button, we’ll connect your call and share your phone number with the flower shop.</li>
  <li>As described under “When DigiNanny shares your information” section above, we work with other Google’s
services (such as Google Doubleclick Bid Manager) for advertising and ad measurement purposes. In
order to help advertisers decide what ads to show on DigiNanny, Google, and websites &amp; apps that
partner with Google, DigiNanny may share information with these services such as a unique identifier
for advertising (Advertising ID available on Android mobile devices or similar technologies). You
can learn more about how Google uses information from sites or apps that use its services and how
you can control that in <a href="https://policies.google.com/technologies/partner-sites">this page</a>.</li>
  <li>The Ads personalization control described above apply to personalized ads served by DigiNanny <strong>on the
DigiNanny platform</strong> and they do not apply to ads served on third parties platforms, including on
Waze’s behalf.</li>
  <li>When you visit a third party website from the Application, such third party may use cookies on
your computer. The third party’s use of cookies is subject to their own privacy policies and not
to this Privacy Policy. If you wish to study the privacy policies of those third parties you
should visit their services, or contact them directly.</li>
</ul>

<p>The Services includes an internal messaging system which provides you the ability to send and
receive messages to and from other users, and to receive marketing messages from us. You hereby
agree that we may use the internal messaging system for the purpose of informing you regarding
products or services, which may interest you.</p>

<p>Please note however that other DigiNanny users are not allowed to use your contact details or our
internal messaging account for commercial advertising purposes. If you have encountered any user
generated advertising material, please inform us through the channels listed on the “About” menu in
the Software or through the “Contact Us” page on the Site <a href="/about/contact">link here</a>.</p>

<p>It is clarified that we are not responsible for the content of said advertisements and the products
delivered or services rendered thereby by third parties and you irrevocably and unconditionally
agree that we shall not be held responsible or liable for any loss, damage, cost or expense that you
may suffer or incur as a result or in connection thereof.</p>

<h1 id="information-security">Information security</h1>

<p>DigiNanny considers information security to be a top priority. DigiNanny services are built with strong
security features that continuously protect your information. DigiNanny implements systems, applications
and procedures to secure your personal information, to minimize the risk of theft, damage, loss of
information, or unauthorized access or use of information. For example, we review our information
collection, storage, and processing practices, including physical security measures, to prevent
unauthorized access to our systems. In addition, we restrict access to personal information to DigiNanny
employees, contractors, and agents who need that information in order to process it. Anyone with
this access is subject to strict contractual confidentiality obligations and may be disciplined or
terminated if they fail to meet these obligations.</p>

<p>The insights we gain from maintaining our services help us detect and automatically block security
threats from ever reaching you.</p>

<p>However, these measures are unable to provide absolute assurance. Therefore, although DigiNanny takes
great efforts to protect your personal information, DigiNanny cannot guarantee and you cannot reasonably
expect that Waze’s databases will be immune from any wrongdoings, malfunctions, unlawful
interceptions or access, or other kinds of abuse and misuse.</p>

<h1 id="compliance-&amp;-cooperation-with-regulators">Compliance &amp; cooperation with regulators</h1>

<p>We regularly review this Privacy Policy and make sure that we process your information in ways that
comply with it.</p>

<h3 id="data-transfers">Data transfers</h3>

<p>We store data on servers located around the world and your information may be processed on servers
located outside of the country where you live. Data protection laws vary among countries, with some
providing more protection than others. Regardless of where your information is processed, we apply
the same protections described in this policy.</p>

<p>When we receive formal written complaints, we respond by contacting the person who made the
complaint. We work with the appropriate regulatory authorities, including local data protection
authorities, to resolve any complaints regarding the transfer of your data that we cannot resolve
with you directly.</p>

<h3 id="european-requirements">European requirements</h3>

<p>If the European Union (EU) data protection law applies to the processing of your information, we
provide the controls described in this policy so you can exercise your right to request access to,
update, remove, delete, and restrict the processing of your information.</p>

<p>You can also export a copy of data in your DigiNanny account if you want to back it up or export your
information to another service. You can log in to the dashboard by going to the
<a href="/dashboard">DigiNanny website</a>, then Settings, then choose Download your data archive. Note that in
order to access the dashboard you will need a DigiNanny account with a valid email address to receive
your data archive.</p>

<p>You also have the right to object to the processing of your information.</p>

<p>We process your information for the purposes described in this policy, based on the following legal
grounds:</p>

<p><em>With your consent</em></p>

<p>We ask for your agreement to process your information for specific purposes and you have the right
to withdraw your consent at any time. You can manage your settings at any time, including your
in-app Privacy Settings.</p>

<p><em>When we’re pursuing legitimate interests</em></p>

<p>We process your information for our legitimate interests and those of third parties (such as
partners including advertisers, in order to report use statistics) while applying appropriate
safeguards that protect your privacy. This means that we process your information for things like:</p>

<ul>
  <li>Providing, maintaining, and improving our services to meet the needs of our users</li>
  <li>Developing new services and features that are useful for our users</li>
  <li>Understanding how people use our services (including how they interact with our ads) to ensure and
improve the performance of our services</li>
  <li>Customizing our services to provide you with a better user experience</li>
  <li>Marketing to inform users about our services</li>
  <li>Providing advertising to make our services freely available for users</li>
  <li>Detecting, preventing, or otherwise addressing fraud, abuse, security, or technical issues with
our services</li>
  <li>Protecting against harm to the rights, property or safety of DigiNanny, our users, or the public as
required or permitted by law</li>
  <li>Performing research that improves our services for our users and benefits the public</li>
  <li>Fulfilling obligations to our partners like developers and rights holders</li>
  <li>Enforcing legal claims, including investigation of potential violations of applicable Terms of
Service</li>
</ul>

<p><em>When we’re providing a service</em></p>

<p>We process your data to provide a service you’ve asked for under a contract (including our terms of
use).</p>

<p><em>When we’re complying with legal obligations</em></p>

<p>We’ll process your data when we have a legal obligation to do so, for example, if we’re responding
to legal process.</p>

<p>If you have questions, you can contact DigiNanny and our data protection office, led by our Data
Protection Officer, at: <a href="mailto:privacy@digiNanny.com">privacy@digiNanny.com</a>. And you can contact your local data protection authority
if you have concerns regarding your rights under local law.</p>

<h1 id="changes-to-this-privacy-policy">Changes to this Privacy Policy</h1>

<p>DigiNanny may from time to time change the terms of this Privacy Policy. Substantial changes will take
effect 30 days after DigiNanny has posted a:</p>

<ul>
  <li>System message to users notifying them of changes to the Privacy Policy; and</li>
  <li>Notification on the Website’s homepage or any other relevant web pages, explaining the changes to
the Privacy Policy alongside a comparison of the “old” and “new” versions of the Privacy Policy.</li>
</ul>

<p>Other changes will take effect 7 days after their initial posting on the Website. However, if DigiNanny
amends this Privacy Policy to comply with legal requirements, the amendments will become effective
immediately upon their initial posting, or as required.</p>

<p>You agree to be bound by any of the changes made in the terms of this Privacy Policy. Continuing to
use the Services will indicate your acceptance of the amended terms. If you do not agree with any of
the amended terms, you must avoid any further use of the Services.</p>

<p><strong>Last modified: 24 November 2019</strong></p>

<p><strong>The Company has registered a database with the Canadian Database Registrar</strong></p>

            
   
            </div>
            </div>
        <div className=" row spaceing30">&nbsp;</div>
    </div>
   ); 
 }
 
 export default Privacy;