import React, { Component } from "react";
//import {Link} from "react-router-dom";
import "./css/bootstrap.min.css";
import "./css/animate.css";
import "./css/Style.css";
import { DownloadPanel } from "./Download";
import * as bootstrap from "bootstrap";
let WOW = require("wowjs").WOW;

function Home() {
  return (
    <div className="container">
      <div className=" row spaceing30 "> &nbsp; </div>
      <div className="row ">
        <div className="col-md-6 col-sm-12 textContainer boxPadding ">
          <h3>
            Utilizing real-time data in favor of your kid’s development progress
          </h3>
          {/*<div className=" textContainer imgContainer">
             <img
              src={require("./images/logo4.png")}
              alt=""
              className="img1_LeftLogo  wow fadeInDown "
            /> 
            <a
              href="https://web.diginanny.com/#/map"
              target="_blank"
              className="btnDownload textContainer"
            >
              Web App
            </a>
          </div>*/}
        </div>
        <div className="col-md-6 col-sm-12 rounded imgContainer img1_back ">
          <img
            src={require("./images/map1-1.jpg")}
            alt=""
            className="rounded imgLayers"
          />
          <img
            alt=""
            src={require("./images/map1-3.png")}
            className="imgLayers wow bounceInDown delay-1s"
          />
          <img
            alt=""
            src={require("./images/map1-2.png")}
            className="imgLayers wow bounceIn delay-2s"
          />
        </div>
      </div>
      <div className=" row spaceing5"> &nbsp; </div>
      <div className="row spaceing30 flex-row-reverse">
        <div className="col-md-6 col-sm-12 textContainer boxPadding">
          <h3> Find your kid’s nearby best hanging out locations </h3>
          <br />
          <h6 className="textGray">
            And explore all the available opportunities for their development
            progress
          </h6>
        </div>
        <div className="col-md-6 col-sm-12 rounded imgContainer img2_back ">
          <img
            alt=""
            src={require("./images/map2-1.png")}
            className="imgLayers"
          />
          <img
            alt=""
            src={require("./images/map2-2.png")}
            className="imgLayers wow bounceIn delay-1s"
          />
          <img
            alt=""
            src={require("./images/map2-3.png")}
            className="imgLayers wow flipInY delay-2s"
          />
          <img
            alt=""
            src={require("./images/map2-4.png")}
            className="imgLayers wow bounceInRight delay-3s"
          />
          <img
            alt=""
            src={require("./images/map2-5.png")}
            className="imgLayers wow bounceInLeft delay-4s"
          />
        </div>
      </div>

      <div className="row spaceing30 ">
        <div className="col-md-6 col-sm-12 textContainer boxPadding">
          <h3>
            Receive and share accessible locations and the quality of your kid’s
            experience with other parents
          </h3>
          <br />
          <h6 className="textGray">
            Help to create a barrier free real-time map for to address kids’
            special needs and let your kid to benefit from it
          </h6>
        </div>
        <div className="col-md-6 col-sm-12 rounded imgContainer img4_back wow">
          <img
            alt=""
            src={require("./images/map4-1.png")}
            className="imgLayers"
          />
          <img
            alt=""
            src={require("./images/map4-2.png")}
            className="imgLayers wow bounceInLeft delay-1s"
          />
          <img
            alt=""
            src={require("./images/map4-3.png")}
            className="imgLayers wow bounceInRight delay-2s"
          />
          <img
            alt=""
            src={require("./images/map4-4.png")}
            className="imgLayers wow flipInY delay-3s"
          />
          <img
            alt=""
            src={require("./images/map4-5.png")}
            className="imgLayers wow bounceIn delay-4s"
          />
        </div>
      </div>
      <DownloadPanel />
      <div className=" row spaceing30">&nbsp;</div>

      <div className="row flex-row-reverse ">
        <div className="col-md-6 col-sm-12 textContainer boxPadding ">
          <h3> How to use DigiNanny ? </h3>
          <h6> 1: Register</h6>
          <h6> 2: Add your kid</h6>
          <h6> 3: Set a date and Enjoy!</h6>
        </div>

        <div
          id="carouselExampleIndicators"
          className="col-md-6 col-sm-12 rounded img8_back imgContainer carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner  ">
            <div className="carousel-item active  ">
              <img
                src={require("./images/howToUse1.png")}
                style={{ width: "50%" }}
                className=" d-block rounded marginLeft25"
                alt="..."
              />
            </div>
            <div className="carousel-item  ">
              <img
                src={require("./images/howToUse2.png")}
                style={{ width: "50%" }}
                className=" d-block rounded marginLeft25"
                alt="..."
              />
            </div>
            <div className="carousel-item  ">
              <img
                src={require("./images/howToUse3.png")}
                style={{ width: "50%" }}
                className=" d-block  rounded marginLeft25"
                alt="..."
              />
            </div>
            <img
              src={require("./images/howToUse0.png")}
              style={{ width: "50%" }}
              className="imgLayers  marginLeft25"
              alt="..."
            />
          </div>
        </div>
      </div>

      <div className="row spaceing30 ">
        <div className="col-md-6 col-sm-12 textContainer boxPadding">
          <h3> Improve your kid’s social and physical skills with DigiNanny</h3>
          <br />
          <h6 className="textGray">
            {" "}
            Being the best parent is to provide your kid with best
            opportunities!
          </h6>
        </div>
        <div className="col-md-6 col-sm-12 rounded imgContainer img7_back">
          <img
            alt=""
            src={require("./images/map7-1.png")}
            className="imgLayers"
          />
          <img
            alt=""
            src={require("./images/map7-2.png")}
            className="imgLayers wow zoomIn delay-1s"
          />
          <img
            alt=""
            src={require("./images/map7-3.png")}
            className="imgLayers wow zoomIn delay-2s "
          />
          <img
            alt=""
            src={require("./images/map7-4.png")}
            className="imgLayers wow zoomIn delay-3s "
          />
          <img
            alt=""
            src={require("./images/map7-5.png")}
            className="imgLayers wow zoomIn delay-4s "
          />
        </div>
      </div>
    </div>
  );
}

class App extends Component {
  componentDidMount() {
    var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      callback: function(box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null // optional scroll container selector, otherwise use window
    });
    wow.init();
  }

  render() {
    return <Home />;
  }
}
export default App;
