import React from 'react'
import './css/bootstrap.min.css';
import './css/Style.css';


function JoinUs(){
    return(
        <div className="container ">
        <div className=" row spaceing30"> </div>
        <div className="contactContainer">
            <h1>Join Us</h1>
           <h6>   
           Diginanny always welcome the talented who wants to make the future.
           </h6>
           <p>
Please don't hesitate to <a href="/Contact">contact us</a>  
</p>
<ul>
    <li>
        if you want to invest in a potential startup  
    </li>
    <li>
        if you have any collaboration idea
    </li>
    <li>
        if you have a great talent in any related domain
    </li>
    <li>
        if you have a strong will to make the future, but don't know how!
    </li>
</ul>

       </div>
        <div className=" row spaceing30"> </div>
        <div className=" row spaceing30"> </div>
        <div className=" row spaceing30"> </div>
         
    </div>
   ); 
 }
 
 export default JoinUs;