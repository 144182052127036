import React, { Component } from "react";
//import {Link} from "react-router-dom";
import "./css/bootstrap.min.css";
import "./css/Style.css";
import "./css/animate.css";
let WOW = require("wowjs").WOW;

export function DownloadPanel() {
  return (
    <div>
      <div className="row spaceing10 downloadPanel  textContainer imgContainer  ">
        <img
          src={require("./images/downloadBack1.jpg")}
          alt=""
          className="imgLayersDownload roundedTop "
        />
        <div className="absoluteLayer textContainer imgContainer">
          <img
            src={require("./images/downloadLogo.png")}
            alt=""
            className="imgDownloadLogo  wow fadeInDown "
          />
          <a
            href="https://web.diginanny.com/#/map"
            target="_blank"
            className="btnDownload textContainer"
          >
            {" "}
            Web App
          </a>
        </div>
      </div>
      <div className="row  downloadPanel  textContainer imgContainer  ">
        <img
          src={require("./images/downloadBack2.jpg")}
          alt=""
          className="imgLayersDownload roundedBottom "
        />
        <img
          src={require("./images/downloadBtn.png")}
          alt=""
          className="imgLayers100  wow fadeInUp"
        />
      </div>
    </div>
  );
}
function DownloadBody() {
  return (
    <div className="container">
      <div className=" row spaceing30">&nbsp;</div>

      <DownloadPanel />

      <div className="spaceing30">&nbsp;</div>
    </div>
  );
}

class Download extends Component {
  componentDidMount() {
    var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      callback: function(box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null // optional scroll container selector, otherwise use window
    });
    wow.init();
  }

  render() {
    return <DownloadBody />;
  }
}
export default Download;
