import React from 'react'
import './css/bootstrap.min.css';
import './css/Style.css';

function FixMap(){
    return(
     <div className="container ">
         <div className=" row spaceing30"> </div>
         <div className="contactContainer">
             <h1>Fix a map issue</h1>
             <div className="row flex-row-reverse">
                  
                 <div className="col-md-8 col-xs-12">
                     <h5> </h5>
                     <p></p>
                 </div>
             </div>
         </div>
         <div className=" row spaceing30"> </div>
         
     </div>
   ); 
 }
 
 export default FixMap;